import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/Login';
import Register from './components/Register';
import Konfiguration from './components/Konfiguration';
import Dashboard from './components/Dashboard';
import Patients from "./components/Patients.js";
import Mandanten from "./components/Mandanten.js"; 
import Doctors from "./components/Doctors.js"; 
import Rendezvous from "./components/Rendezvous.js"; 
import Diagnostik from "./components/Diagnostik.js"; 
import Assurances from "./components/Partenaires.js";  
import Docteurs from "./components/Docteurs.js";
import Finances from "./components/Finances.js";  

import Analyze from "./components/Analyze.js"; 


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/konfiguration" element={<Konfiguration />} />
      {/*    <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/Mandanten" element={<Mandanten />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/rendezvous" element={<Rendezvous />} />
          <Route path="/diagnostik" element={<Diagnostik />} />
          <Route path="/assurances" element={<Assurances />} />
          <Route path="/docteurs" element={<Docteurs />} />
          <Route path="/finances" element={<Finances />} />   
          <Route path="/patients" element={<Patients />} />
          <Route path="/Analyze" element={<Analyze />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);