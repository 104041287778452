import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import HandshakeIcon from '@mui/icons-material/Handshake';

const blueColor = '#336B87'; // Définir la couleur bleue

export const mainListItems = (
  <React.Fragment>

    {/*
    <ListItemButton component={Link} to="/Dashboard">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Tableau de bord" />
    </ListItemButton>
    
 
    <Divider />
    
  */}
    <ListSubheader component="div" inset>
      PATIENTS
    </ListSubheader>
  
    <ListItemButton component={Link} to="/Docteurs">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <LocalHospitalIcon />
      </ListItemIcon>
      <ListItemText primary="Docteurs" />
    </ListItemButton>
    <ListItemButton component={Link} to="/Patients">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <AddReactionIcon />
      </ListItemIcon>
      <ListItemText primary="Patients" />
    </ListItemButton>
    <ListItemButton component={Link} to="/Rendezvous">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Consultations" />
    </ListItemButton>
    <Divider />
    <ListSubheader component="div" inset>
      FINANCES
    </ListSubheader>
    <ListItemButton component={Link} to="/Finances">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <AccountBalanceIcon />
      </ListItemIcon>
      <ListItemText primary="Caisse" />
    </ListItemButton>
    <Divider />
    <ListSubheader component="div" inset>
      Assurances
    </ListSubheader>

    <ListItemButton component={Link} to="/Assurances">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <HandshakeIcon />
      </ListItemIcon>
      <ListItemText primary="Assurances" />
    </ListItemButton>
    <Divider />
    <ListSubheader component="div" inset>
      SYSTÈME
    </ListSubheader>
    <ListItemButton component={Link} to="/Konfiguration">
      <ListItemIcon sx={{ color: '#34A836' }}>
        <PermDataSettingIcon />
      </ListItemIcon>
      <ListItemText primary="Configuration" />
    </ListItemButton>
   
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
