import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import { FaFileAlt } from 'react-icons/fa';

const drawerWidth = 240;

// Ajouter un intercepteur Axios pour ajouter l'en-tête d'autorisation à chaque demande
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#34A836',
      dark: '#115293',
    },
    secondary: {
      main: '#e3f2fd',
    },
  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#e3f2fd',
  borderRadius: '10px 10px 0 0',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [patients, setPatients] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [partners, setPartners] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [visits, setVisits] = useState([]);
  const [addVisitOpen, setAddVisitOpen] = useState(false);
  const [addPatientOpen, setAddPatientOpen] = useState(false);
  const [viewPatientOpen, setViewPatientOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const [editPatientOpen, setEditPatientOpen] = useState(false);
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addErrorOpen, setAddErrorOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [activeButton, setActiveButton] = useState('all');
  const [selected, setSelected] = useState('');
  const [orderBy, setOrderBy] = useState('lastName');
  const [order, setOrder] = useState('asc');
  const [action, setAction] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [addManagerOpen, setAddManagerOpen] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);

  useEffect(() => {
    const fetchPatients = async () => {
      const response = await axios.get('api/patients');
      setPatients(response.data);
    };

    const fetchTenants = async () => {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    };

      const fetchPartners = async () => {
        try {
          const response = await axios.get('api/partners');
          console.log("Partners fetched successfully:", response.data); // Debugging line
          setPartners(response.data);
        } catch (error) {
          console.error("Error fetching partners:", error);
        }
      };
  

    const fetchDoctors = async () => {
      const response = await axios.get('api/doctors');
      setDoctors(response.data);
    };

    const fetchVisits = async () => {
      const response = await axios.get('api/visits');
      setVisits(response.data);
    };

    fetchPatients();
    fetchTenants();
    fetchPartners();
    fetchDoctors();
    fetchVisits();
  }, []);

  const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten


  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen

  useEffect(() => {
    if (action === 'view' && selected) {
      const patient = patients.find(pat => pat.id === selected);
      handleViewPatientOpen(patient);
    } else if (action === 'edit' && selected) {
      const patient = patients.find(pat => pat.id === selected);
      handleEditPatientOpen(patient);
    } else if (action === 'delete') {
      setDeleteConfirmOpen(true);
    }
    setAction('');
  }, [action, selected, patients]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAddPatientOpen = () => {
    setAddPatientOpen(true);
  };

  const handleEditPatientOpen = (patient) => {
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
    setEditPatientOpen(true);
  };

  const handleAddPatientClose = () => {
    setAddPatientOpen(false);
  };

  const handleEditPatientClose = () => {
    setEditPatientOpen(false);
  };

  const handleAddPatient = async (newPatient) => {
    try {
      const response = await axios.post('api/patients', newPatient);
      setPatients((prevPatients) => [...prevPatients, response.data]);
      setAddPatientOpen(false);
      setAddSuccessOpen(true);
    } catch (error) {
      console.error('Failed to add patient', error);
      setAddPatientOpen(false);
      setAddErrorOpen(true);
    }
  };

  const handleEditPatient = async (updatedPatient) => {
    try {
      const response = await axios.put(`api/patients/${updatedPatient.id}`, updatedPatient);
      setPatients((prevPatients) =>
        prevPatients.map((patient) =>
          patient.id === updatedPatient.id ? response.data : patient
        )
      );
      setEditPatientOpen(false);
      setEditSuccessOpen(true);
    } catch (error) {
      console.error('Failed to update patient', error);
      setEditPatientOpen(false);
      setEditErrorOpen(true);
    }
  };

  const handleViewPatientOpen = (patient) => {
    setSelectedPatient({
      ...patient,
      dateOfBirth: dayjs(patient.dateOfBirth),
      created_at: dayjs(patient.created_at),
    });
    setTabIndex(0);
    setViewPatientOpen(true);
  };

  const handleViewPatientClose = () => {
    setViewPatientOpen(false);
  };

  const handlePatientClick = (id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  const handleDeletePatient = async () => {
    try {
      await axios.delete(`api/patients/${selected}`);
      setPatients((prevPatients) =>
        prevPatients.filter((patient) => patient.id !== selected)
      );
      setSelected('');
      setDeleteConfirmOpen(false);
      setDeleteSuccessOpen(true);
    } catch (error) {
      console.error('Failed to delete patient', error);
      setDeleteConfirmOpen(false);
      setDeleteErrorOpen(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleFilterChange = (filter) => {
    setActiveButton(filter);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAddManagerOpen = (departmentId) => {
    setSelectedDepartmentId(departmentId);
    setAddManagerOpen(true);
  };

  const handleAddManagerClose = () => {
    setAddManagerOpen(false);
    setSelectedDepartmentId(null);
  };

  const handleAddManager = async (departmentId, managerId) => {
    try {
      await axios.post(`/departments/${departmentId}/managers`, { managerId });
    } catch (error) {
      console.error('Failed to add manager', error);
    }
  };

  const handleAddVisitOpen = () => {
    setAddVisitOpen(true);
  };

  const handleAddVisitClose = () => {
    setAddVisitOpen(false);
  };

  const handleAddVisit = async (newVisit) => {
    try {
      const response = await axios.post('api/visits', newVisit);
      setVisits((prevVisits) => [...prevVisits, response.data]);
      setAddVisitOpen(false);
    } catch (error) {
      console.error('Failed to add visit', error);
      setAddVisitOpen(false);
    }
  };

  const filteredPatients = patients.filter((patient) => {
    if (activeButton === 'present') return patient.status === 'anwesend';
    if (activeButton === 'absent') return patient.status === 'abwesend';
    return true;
  });

  const sortedPatients = filteredPatients.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  const searchedPatients = patients.filter((patient) => {
    // Sicherstellen, dass die Felder existieren und Strings sind
    const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
    const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
    const patientNumber = patient.patientNumber ? patient.patientNumber.toString().toLowerCase() : '';
    const role = patient.role ? patient.role.toLowerCase() : '';
    const email = patient.email ? patient.email.toLowerCase() : '';
    const phoneNumber = patient.phoneNumber ? patient.phoneNumber.toString().toLowerCase() : '';
  
    return (
      lastName.includes(searchFilter.toLowerCase()) ||
      firstName.includes(searchFilter.toLowerCase()) ||
      patientNumber.includes(searchFilter.toLowerCase()) ||
      role.includes(searchFilter.toLowerCase()) ||
      email.includes(searchFilter.toLowerCase()) ||
      phoneNumber.includes(searchFilter.toLowerCase())
    );
  });
  

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Gestion des patients
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 4 }} />
            {secondaryListItems}
            <Divider sx={{ my: 4 }} />
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5 }}>
          <Toolbar />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label="Rechercher"
                variant="outlined"
                size="small"
                value={searchFilter}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: 'white',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '15px',
                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '25px',
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: '#2196f3',
                    },
                    '&:hover fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#1976d2' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            
              
          
              <IconButton onClick={handleAddPatientOpen}>
                <AddCircleIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
             
              <Select
                value={action}
                onChange={handleActionChange}
                displayEmpty
                sx={{ ml: 2, color: theme.palette.primary.main, fontWeight: 'bold', borderBottom: `2px solid ${theme.palette.primary.main}` }}
              >
                <MenuItem value="" disabled>
                  Choisir une action
                </MenuItem>
                <MenuItem value="view" disabled={!selected}>
                  Voir
                </MenuItem>
                <MenuItem value="edit" disabled={!selected}>
                  Éditer
                </MenuItem>
                <MenuItem value="delete" disabled={!selected}>
                  Supprimer
                </MenuItem>
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Typography variant="body1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold', marginBottom: 2, color: theme.palette.primary.dark }}>
              Patients: {patients.length}
            </Typography>
          </Box>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <RadioGroup
                      name="patientSelection"
                      value={selected}
                      onChange={(e) => handlePatientClick(e.target.value)}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label=""
                      />
                    </RadioGroup>
                  </StyledTableCell>
                  <StyledTableCell>Image</StyledTableCell>
              
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'firstName'}
                      direction={orderBy === 'firstName' ? order : 'asc'}
                      onClick={() => handleRequestSort('firstName')}
                    >
                      Nom
                    </TableSortLabel>
                  </StyledTableCell>

                  {/* Spalte NAchname */}
               {/*}
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'lastName'}
                      direction={orderBy === 'lastName' ? order : 'asc'}
                      onClick={() => handleRequestSort('lastName')}
                    >
                      Nom
                    </TableSortLabel>
                  </StyledTableCell>

               */}
            {/*      <StyledTableCell>Date de naissance</StyledTableCell>        */}
                  <StyledTableCell>Sexe</StyledTableCell>
             {/*     <StyledTableCell>Email</StyledTableCell>          */}
                  <StyledTableCell>Numéro de téléphone</StyledTableCell>
        {/*          <StyledTableCell>Adresse</StyledTableCell>               */}
        {/*          <StyledTableCell>Numéro d'assurance</StyledTableCell>    */}
                  <StyledTableCell>Date de création</StyledTableCell>
                  <StyledTableCell>Mandataire</StyledTableCell>
                  <StyledTableCell>Assurance</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedPatients.map((patient) => {
                  const isItemSelected = isSelected(patient.id);
                  const tenantName = tenants.find(tenant => tenant.id === patient.tenantId)?.name || 'Inconnu';
                  const partnerName = partners.find(partner => partner.id === patient.partnerId)?.name || 'Inconnu';
                  return (
                    <StyledTableRow
                      hover
                      onClick={() => handlePatientClick(patient.id)}
                      role="radio"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={patient.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Radio
                          color="primary"
                          checked={isItemSelected}
                          onChange={() => handlePatientClick(patient.id)}
                          value={patient.id}
                        />
                      </TableCell>
                      <TableCell>
                        <Avatar
                          src={patient.imageUrl}
                          alt={patient.firstName}
                          sx={{
                            width: 60,
                            height: 60,
                          }}
                        />
                      </TableCell>
                      <TableCell>{patient.firstName}</TableCell>
                {/*      <TableCell>{patient.lastName}</TableCell>   */}
                {/*      <TableCell>{dayjs(patient.dateOfBirth).format('DD/MM/YYYY')}</TableCell>    */}
                      <TableCell>{patient.gender}</TableCell>
               {/*       <TableCell>{patient.email}</TableCell>    */}
                      <TableCell>{patient.phoneNumber}</TableCell>
                {/*      <TableCell>{patient.address}</TableCell>   */}
                {/*      <TableCell>{patient.insuranceNumber}</TableCell>   */}
                      <TableCell>{dayjs(patient.created_at).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{tenantName}</TableCell>
                      <TableCell>{partners.find(partner => partner.id === patient.partnerId)?.Name || 'Inconnu'}</TableCell>

                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <AddPatientDialog
            open={addPatientOpen}
            onClose={handleAddPatientClose}
            onAddPatient={handleAddPatient}
            tenants={tenants}
            partners={partners} // Pass partners to AddPatientDialog
          />
          {selectedPatient && (
            <>
              <ViewPatientDialog
                open={viewPatientOpen}
                onClose={handleViewPatientClose}
                patient={selectedPatient}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                patients={patients}
                tenants={tenants}
                partners={partners} // Pass partners to ViewPatientDialog
              />
              <EditPatientDialog
                open={editPatientOpen}
                onClose={handleEditPatientClose}
                patient={selectedPatient}
                onEditPatient={handleEditPatient}
                tenants={tenants}
                partners={partners} // Pass partners to EditPatientDialog
              />
            </>
          )}
 
          <Dialog
            open={deleteConfirmOpen}
            onClose={() => setDeleteConfirmOpen(false)}
          >
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer le patient sélectionné ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                Annuler
              </Button>
              <Button onClick={handleDeletePatient} color="primary">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={deleteSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteSuccessOpen(false)}
          >
            <Alert onClose={() => setDeleteSuccessOpen(false)} severity="success">
              Patient supprimé avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={deleteErrorOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteErrorOpen(false)}
          >
            <Alert onClose={() => setDeleteErrorOpen(false)} severity="error">
              Erreur lors de la suppression du patient !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setAddSuccessOpen(false)}
          >
            <Alert onClose={() => setAddSuccessOpen(false)} severity="success">
              Patient ajouté avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addErrorOpen}
            autoHideDuration={6000}
            onClose={() => setAddErrorOpen(false)}
          >
            <Alert onClose={() => setAddErrorOpen(false)} severity="error">
              Erreur lors de l'ajout du patient !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setEditSuccessOpen(false)}
          >
            <Alert onClose={() => setEditSuccessOpen(false)} severity="success">
              Patient mis à jour avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editErrorOpen}
            autoHideDuration={6000}
            onClose={() => setEditErrorOpen(false)}
          >
            <Alert onClose={() => setEditErrorOpen(false)} severity="error">
              Erreur lors de la mise à jour du patient !
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const AddPatientDialog = ({ open, onClose, onAddPatient }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [insuranceNumber, setInsuranceNumber] = useState('');
  const [imageName, setImageName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [tenants, setTenants] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get('api/tenants');
        setTenants(response.data);
      } catch (error) {
        console.error('Failed to fetch tenants', error);
      }
    };
    
    const fetchPartners = async () => {
      try {
        const response = await axios.get('api/partners');
        setPartners(response.data);
      } catch (error) {
        console.error('Failed to fetch partners', error);
      }
    };

    fetchTenants();
    fetchPartners();
  }, []);

  useEffect(() => {
    if (tenants.length > 0) {
      setTenantId(tenants[0].id); // Setzt den ersten Mandanten als Standardwert
    }
    if (partners.length > 0) {
      setPartnerId(partners[0].id); // Setzt den ersten Partner als Standardwert
    }
  }, [tenants, partners]); // Führt den Effekt aus, wenn sich die Listen der Mandanten oder Partner änderndert

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const imageUrl = `/images/${imageName}`;
      const newPatient = {
        firstName,
        lastName,
        dateOfBirth: dateOfBirth.toISOString(),
        gender,
        email,
        phoneNumber,
        address,
        insuranceNumber,
        imageUrl,
        tenantId,
        partnerId,
      };
      await onAddPatient(newPatient);
      handleClose(); // Formular leeren nach dem Hinzufügen
      if (tenants.length > 0) {
        setTenantId(tenants[0].id); // Setzt den ersten Mandanten wieder als Standardwert
      }
      if (partners.length > 0) {
        setPartnerId(partners[0].id); // Setzt den ersten Partner wieder als Standardwert
      }
    } catch (error) {
      console.error('Failed to add patient', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setDateOfBirth(dayjs());
    setGender('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setInsuranceNumber('');
    setImageName('');
    setTenantId(tenants[0]?.id || ''); // Setzt den ersten Mandanten als Standardwert zurück

    setPartnerId(partners[0]?.id || ''); // Setzt den ersten Partner als Standardwert zurück
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Ajouter un patient</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>

            {/*Nachname*/}
        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Nom"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
        */}  


          {/*Geburtsdatum*/}
         {/*
           <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de naissance"
                  value={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </LocalizationProvider>
            </Grid>

            */}

            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Sexe</InputLabel>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Femme">Femme</MenuItem>
                  <MenuItem value="Homme">Homme</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/*EMAIL*/}

        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

        */}
           <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro de téléphone"
                fullWidth
                value={phoneNumber}
                onChange={(e) => {
                  const input = e.target.value;
                  // Nur numerische Zeichen erlauben
                  const numericValue = input.replace(/\D/g, ''); 
                  setPhoneNumber(numericValue ? parseInt(numericValue) : '');
                }}
                required
              />
            </Grid>


            {/*Adresse*/}

        {/*    
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Adresse"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
        */}

        {/*Versicherungsnummer*/}

      {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro d'assurance"
                fullWidth
                value={insuranceNumber}
                onChange={(e) => setInsuranceNumber(e.target.value)}
              />
            </Grid>
        
      */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Mandataire</InputLabel>
                <Select
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                >
            {tenants.length > 0 && tenants.map((tenant) => (

                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Partenaire</InputLabel>
                <Select
                  value={partnerId}
                  onChange={(e) => setPartnerId(e.target.value)}
                >
                   {partners.length > 0 && partners.map((partner) => (
                    <MenuItem key={partner.id} value={partner.id}>
                      {partner.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Sélectionner une image
                </Button>
              </label>
              {imageName && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Fichier sélectionné: {imageName}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};


// Innerhalb von EditPatientDialog
function EditPatientDialog({ open, onClose, patient, onEditPatient, tenants, partners }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [insuranceNumber, setInsuranceNumber] = useState('');
  const [imageName, setImageName] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [partnerId, setPartnerId] = useState('');

  useEffect(() => {
    if (patient) {
      setFirstName(patient.firstName || '');
      setLastName(patient.lastName || '');
      setDateOfBirth(dayjs(patient.dateOfBirth || new Date()));
      setGender(patient.gender || '');
      setEmail(patient.email || '');
      setPhoneNumber(patient.phoneNumber || '');
      setAddress(patient.address || '');
      setInsuranceNumber(patient.insuranceNumber || '');
      setImageName(patient.imageUrl || '');
      setTenantId(patient.tenantId || '');
      setPartnerId(patient.partnerId || '');
    }
  }, [patient]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedPatient = {
      ...patient,
      firstName,
      lastName,
      dateOfBirth: dateOfBirth.toISOString(),
      gender,
      email,
      phoneNumber,
      address,
      insuranceNumber,
      imageUrl: `/images/${imageName}`,
      tenantId,
      partnerId,
    };

    onEditPatient(updatedPatient);
    handleClose(); // Leert das Formular nach dem Schließen
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setDateOfBirth(dayjs());
    setGender('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setInsuranceNumber('');
    setImageName('');
    setTenantId('');
    setPartnerId('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Éditer un patient</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>

        {/* 
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Nom"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
        */}

        {/*
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de naissance"
                  value={dateOfBirth}
                  onChange={(newValue) => setDateOfBirth(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
                />
              </LocalizationProvider>
            </Grid>
        */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Sexe</InputLabel>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Femme">Femme</MenuItem>
                  <MenuItem value="Homme">Homme</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
        */}
         <Grid item xs={12} md={6}>
  <TextField
    margin="dense"
    label="Numéro de téléphone"
    fullWidth
    value={phoneNumber}
    onChange={(e) => {
      const value = e.target.value;
      // Nur numerische Zeichen zulassen
      if (/^\d*$/.test(value)) {
        setPhoneNumber(value); // Aktualisiert nur, wenn es numerisch ist
      }
    }}
    required
  />
</Grid>

        {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Adresse"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
        */}
      
      {/*
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Numéro d'assurance"
                fullWidth
                value={insuranceNumber}
                onChange={(e) => setInsuranceNumber(e.target.value)}
              />
            </Grid>
      */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Mandataire</InputLabel>
                <Select
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                >
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Partenaire</InputLabel>
                <Select
                  value={partnerId}
                  onChange={(e) => setPartnerId(e.target.value)}
                >
                  {partners.map((partner) => (
                    <MenuItem key={partner.id} value={partner.id}>
                      {partner.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Sélectionner une image
                </Button>
              </label>
              {imageName && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Fichier sélectionné: {imageName}
                </Typography>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Enregistrer
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const ViewPatientDialog = ({ open, onClose, patient, tabIndex, setTabIndex, partners }) => {
  const [doctors, setDoctors] = useState([]);
  const [visits, setVisits] = useState([]);
  const [diagnoses, setDiagnoses] = useState({});
  const [treatments, setTreatments] = useState({});

  // Références pour l'impression (si nécessaire)
  const prescriptionRef = useRef();
  const diagnosisPrintRef = useRef();
  const invoicePrintRef = useRef();
  const consultationInvoicePrintRef = useRef();

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`api/doctors`);
        setDoctors(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des médecins:', error);
      }
    };

    const fetchVisits = async () => {
      try {
        const response = await axios.get(`api/patients/${patient.id}/visits`);
        setVisits(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des visites:', error);
      }
    };

    if (open && patient && patient.id) {
      fetchDoctors();
      fetchVisits();
    }
  }, [open, patient]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const diagnosesPromises = visits.map(visit =>
          axios.get(`api/visits/${visit.id}/diagnoses`)
        );
        const treatmentsPromises = visits.map(visit =>
          axios.get(`api/visits/${visit.id}/treatments`)
        );

        const diagnosesResponses = await Promise.all(diagnosesPromises);
        const treatmentsResponses = await Promise.all(treatmentsPromises);

        const diagnosesData = {};
        const treatmentsData = {};

        diagnosesResponses.forEach((response, index) => {
          diagnosesData[visits[index].id] = response.data;
        });

        treatmentsResponses.forEach((response, index) => {
          treatmentsData[visits[index].id] = response.data;
        });

        setDiagnoses(diagnosesData);
        setTreatments(treatmentsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des détails des visites:', error);
      }
    };

    if (visits.length > 0) {
      fetchDetails();
    }
  }, [visits]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const partnerName = partners.find(partner => partner.id === patient.partnerId)?.Name || 'Inconnu';

  // Définir l'URL de base de votre serveur (ajustez selon votre configuration)
  const serverBaseUrl = '/uploads'; // Remplacez par votre URL de serveur réelle

  // Fonction pour ouvrir le document dans un nouvel onglet
  const handleOpenDocument = (filePath) => {
    window.open(`${serverBaseUrl}/${filePath}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Voir le patient</DialogTitle>
        <DialogContent>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Informations Général" />

            {/*2. Tab Beschriftung*/}
          
          {/*
            <Tab label="Informations de contact" />
          */}
            <Tab label="Visites" />

            <Tab label="Consultations" />
          </Tabs>
         
       
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Nom"
                  fullWidth
                  value={patient.firstName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              {/* Nachname */}
          {/*
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  label="Nom"
                  fullWidth
                  value={patient.lastName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
         */ }    
              <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    label="Numéro de téléphone"
                    fullWidth
                    value={patient.phoneNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

              {/*Geburstdatum*/}

          {/*
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date de naissance"
                    value={dayjs(patient.dateOfBirth)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="dense"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            
        */}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  label="Sexe"
                  fullWidth
                  value={patient.gender}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Avatar
                  src={patient.imageUrl}
                  alt={patient.firstName}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  label="Assurance"
                  fullWidth
                  value={partnerName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>

    {/*den anderen Tab*/}
      {/*
          <TabPanel value={tabIndex} index={1}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    value={patient.email}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    label="Numéro de téléphone"
                    fullWidth
                    value={patient.phoneNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    label="Adresse"
                    fullWidth
                    value={patient.address}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    label="Numéro d'assurance"
                    fullWidth
                    value={patient.insuranceNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </TabPanel>


        */}
          <TabPanel value={tabIndex} index={1}>
            {visits.map((visit) => {
              const doctor = doctors.find(doc => doc.id === visit.doctorId) || {};
              const visitDiagnoses = diagnoses[visit.id] || [];
              const visitTreatments = treatments[visit.id] || [];

              return (
                <StyledTableContainer component={Paper} key={visit.id} sx={{ marginBottom: '20px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Docteur</StyledTableCell>
                        <StyledTableCell>Raison</StyledTableCell>
                        <StyledTableCell>Remarques</StyledTableCell>
                        <StyledTableCell>Statut</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <TableCell>{dayjs(visit.visitDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{doctor.firstName} {doctor.lastName}</TableCell>
                        <TableCell>{visit.reason}</TableCell>
                        <TableCell>{visit.notes}</TableCell>
                        <TableCell>{visit.status}</TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="subtitle1">Diagnostics :</Typography>
                          {visitDiagnoses.length > 0 ? (
                            <ul>
                              {visitDiagnoses.map((diag) => (
                                <li key={diag.id}>
                                  {diag.diagnosis}
                                  {diag.filePath && (
                                    <Button
                                      variant="text"
                                      color="primary"
                                      startIcon={<FaFileAlt />}
                                      onClick={() => handleOpenDocument(diag.filePath)}
                                      sx={{ marginLeft: '10px' }}
                                    >
                                      Voir le document
                                    </Button>
                                  )}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <Typography variant="body2">Aucun diagnostic disponible</Typography>
                          )}
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="subtitle1">Traitements :</Typography>
                          {visitTreatments.length > 0 ? (
                            <ul>
                              {visitTreatments.map((treat) => (
                                <li key={treat.id}>{treat.treatment}</li>
                              ))}
                            </ul>
                          ) : (
                            <Typography variant="body2">Aucun traitement disponible</Typography>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              );
            })}
          </TabPanel>
          
          <DialogActions>
            <Button onClick={onClose}>Fermer</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Modal pour afficher le document (optionnel, peut être supprimé si non utilisé) */}
      {/* Comme vous souhaitez ouvrir les documents directement dans le navigateur, ce modal n'est pas nécessaire */}
    </>
  );
};
